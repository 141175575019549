@font-face {
  font-family: 'icomoon';
  src: url('../../public/fonts/icomoon.eot?izu81q');
  src: url('../../public/fonts/icomoon.eot?izu81q#iefix') format('embedded-opentype'), url('../../public/fonts/icomoon.ttf?izu81q') format('truetype'),
    url('../../public/fonts/icomoon.woff?izu81q') format('woff'), url('../../public/fonts/icomoon.svg?izu81q#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.clock:before {
    content: "\e91c";
  }
  &.address:before {
    content: "\e91d";
  }
  &.store:before {
    content: "\e915";
  }
  &.info:before {
    content: "\e914";
  }
  &.car:before {
    content: "\e916";
  }
  &.rabbit:before {
    content: "\e917";
  }
  &.smiley:before {
    content: "\e918";
  }
  &.wifi:before {
    content: "\e919";
  }
  &.drink:before {
    content: "\e91a";
  }
  &.location:before {
    content: "\e91b";
  }
  &.telephone:before {
    content: "\e912";
  }
  &.mail:before {
    content: "\e913";
    color: #515151;
  }
  &.left-chevron:before {
    content: "\e911";
  }
  &.copy-to-clipboard:before {
    content: '\e910';
  }
  &.youtube:before {
    content: '\e90f';
  }
  &.menu-bar:before {
    content: '\e90e';
  }
  &.close:before {
    content: '\e90d';
  }
  &.facebook:before {
    content: '\e90a';
  }
  &.instagram:before {
    content: '\e90b';
  }
  &.pinterest:before {
    content: '\e90c';
  }
  &.twitter:before {
    content: '\e907';
  }
  &.linkedin:before {
    content: '\e908';
  }
  &.at-the-rate:before {
    content: '\e909';
  }
  &.text-shape-left:before {
    content: '\e906';
  }
  &.chevron-down:before {
    content: '\e905';
  }
  &.text-shape-right:before {
    content: '\e901';
  }
  &.right-chevron:before {
    content: '\e902';
  }
  &.right-arrow-bold:before {
    content: '\e903';
  }
  &.right-arrow:before {
    content: '\e904';
  }
  &.search:before {
    content: '\e900';
  }
}
